import React, { useState, useEffect, useContext } from "react"
import { graphql } from "gatsby"

import "../../../styles/pages/glasses.scss"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import GlassesHeading from "../../../components/GlassesHeading"
import Breadcrumbs from "../../../components/Breadcrumbs"
import GlassesFilter from "../../../components/GlassesFilter"
import GlassesList from "../../../components/GlassesList"
import ProductBanner from "../../../components/ProductBanner"
import { useDebounce, useGlasses } from "../../../services/hooks"
import CartContext from "../../../context/cartContext"

const Glasses = ({ data, location }) => {
  const hubbleFrames = data.hubbleapi.hubbleFrameProducts.filter(
    ({ collectionString }) => {
      const collectionArray = collectionString.split(",")

      return (
        collectionArray.includes("Hubble") &&
        !collectionArray.includes("Modern")
      )
    }
  )

  const right_side_text =
    "At Hubble, we’ve broken down the best eyeglasses for each face shape. Ever wondered what glasses are best for an oval face? Oval is one of the most versatile face shapes when it comes to glasses. Consider angular frames which can help accent rounded features or round frames which can help to balance the length of the face. Even bold square frames can accent the shape’s length and smoothness for a stunning look.<br><br>No matter what style you choose, we have the perfect pair of glasses for you. Plus, every pair of our glasses has impact-resistant lenses, durable barrel hinges, and padded temple tips for extra cush. Look good, see better—and feel great."
  const list_items = [
    "Premium, natural fiber acetate frames that are stronger, more durable, and last longer than plastic frames.",
    "Anti-reflective, anti-glare, anti-scratch, hydrophobic, and oleophobic coatings.",
    "Optional blue-light filtering.",
    "Hand-assembled and made to order glasses for oval shaped faces.",
  ]

  const { setHeaderColor } = useContext(CartContext)

  const defaultFilter = {
    price: [],
    width: ["oval"],
    shape: [],
    material: [],
    color: [],
    gender: [],
  }

  const params = new URLSearchParams(location.search)

  params.forEach((value, key) => {
    defaultFilter[key] = value.split(",")
  })

  const [filter, setFilter] = useState(defaultFilter)

  const debouncedFilter = useDebounce(filter, 500)

  useEffect(() => {
    const params = new URLSearchParams(location.search)

    params.forEach((value, key) => {
      defaultFilter[key] = value.split(",")
    })

    setFilter(defaultFilter)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  useEffect(() => {
    if (!debouncedFilter) return

    // navigateWithFilters(location.pathname, debouncedFilter)
  }, [debouncedFilter])

  useEffect(() => {
    setHeaderColor("orange")

    return () => {
      setHeaderColor("black")
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const sortedGlasses = useGlasses(hubbleFrames, debouncedFilter)

  useEffect(() => {
    if (typeof window !== undefined ) {
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: "view_item_list",
        ecommerce: {
          items: sortedGlasses.map(( product, index ) => (
            {
              index: index,
              item_id: product.shopifyProductId,
              item_name: product.title,
              currency: "USD",
              discount: 0,
              item_brand: "Hubble",
              item_category: "frames",
              item_category2: 'optical',
              item_category3: product.productGender,
              item_category4: product.material,
              item_category5: product.size,
              item_list_id: `${product.productGender}-Optical-By-Face-Oval`,
              price: parseFloat(product.glassesBasePrice),
            }
          ))
        }
      })
    }
  }, [sortedGlasses])

  const schemaMarkup = [
    {
      "@context": "https://schema.org",
      "@url": "https://hubblecontacts.com/shop-by-face-shape/glasses-oval-face-shape",
      "@type": "webpage",
      "name": "Shop the Best Glasses and Sunglasses for Oval Face Shapes | Hubble",
      "description": "Find the best glasses and sunglasses for oval face shapes. We've hand selected our favorite frames that compliment an oval face shape to make finding the perfect pair easy.",
      "publisher": {
      "@type": "Organization",
      "name": "Hubble Contacts"
      }
      }

  ]

  return (
    <Layout className="glasses">
      <SEO
        title="Shop the Best Glasses for Oval Face Shapes"
        description="Find the best glasses for oval face shapes. We've hand selected our favorite frames that compliment an oval face shape to make finding the perfect pair easy."
        schemaMarkup={schemaMarkup}
        />
      <GlassesHeading
        color="orange"
        title="Shop Glasses for an Oval Face Shape"
        description="Looking for a perfect fit? Let us help you find the best glasses for an oval face, starting at just $49.99."
      />

      <div className="glasses-container">
        <div className="grid-section">
          <Breadcrumbs
            color="orange"
            links={[{ to: "/", label: "Homepage" }, { label: "Eyeglasses" }]}
          />
          <GlassesFilter
            type="glasses"
            glassesLength={sortedGlasses.length}
            filter={filter}
            setFilter={setFilter}
          />
          <GlassesList
            type="glasses"
            glasses={sortedGlasses}
            color={filter.color}
            gender={filter.gender[0]}
          />
          <ProductBanner
            title={"Hubble has the best selection of glasses styles for Oval-shaped faces."}
            right_side_text={right_side_text}
            list_items={list_items}
            optional_image="Hubble-Glasses-PCP-Inset-Lifestyle-Image.jpg"
            optional_image_alt_text="Woman wearing Hubble eyeglasses while enjoing time at home"
            color="orange"
          />
        </div>
      </div>
    </Layout>
  )
}

export default Glasses

export const query = graphql`
  query {
    hubbleapi {
      hubbleFrameProducts {
        title
        handle
        shopifyProductId
        description
        collectionString
        glassesBasePrice
        sunglassesBasePrice
        sunglassesAvailable
        productGender
        maleHeaderImage1
        maleHeaderImage2
        size
        shape
        femaleHeaderImage1
        femaleHeaderImage2
        productImage
        material
        materialFilter
        productClothImage
        sortOrder
        relatedFrames {
          edges {
            node {
              title
              productImage
              hubbleFrameVariants {
                title
                color
                colorFilter
                heroColor
                productImage1
                productImage2
                sunglassesWideImage1
                sunglassesWideImage2
                sunglassesWideImage3
                sunglassesProductImage1
                sunglassesProductImage2
                sunglassesProductImage3
                sunglassesProductImage4
              }
            }
          }
        }
        hubbleFrameVariants {
          title
          shopifyVariantId
          color
          colorFilter
          sku
          heroColor
          availableForSale
          productImage1
          productImage2
          productImage3
          sunglassesWideImage1
          sunglassesWideImage2
          sunglassesWideImage3
          sunglassesProductImage1
          sunglassesProductImage2
          sunglassesProductImage3
          sunglassesProductImage4
        }
      }
    }
  }
`
